import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import Trans from "../../components/Translate";
import Layout from "../../layouts/default";
import RegisterForm from "./components/RegistrationForm";
import "./styles.scss";
import Cookies from "js-cookie";
import Modal from "../../components/Modal";
import AboutContent from "../../components/AboutContent";

const Register = ({ location }) => {
  const [showAboutModel, setShowAboutModel] = useState(false);
  useEffect(() => {
    setShowAboutModel(true);
  }, []);
  return (
    <Layout metaTitle={"Register | Apply to Activate Your Account"}>
      <Modal
        isOpen={showAboutModel}
        title=""
        disableFocusTrap={true}
        disableClose={true}
        onClose={() => {}}
        displayType="overflow"
        customHeader={() => {
          return (
            <>
              <h2 className="modal-title w-100 mb-2 font-weight-bold text-center">
                <Trans i18nKey={"READ_TO_CONTINUE"} />
              </h2>
            </>
          );
        }}
      >
        <>
          <AboutContent />
          <div className="d-flex justify-content-end">
            <button
              tabIndex={"0"}
              className="btn btn-link"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setShowAboutModel(false)}
            >
              <span className="h3">
                <Trans i18nKey={"CONTINUE"} /> &gt;
              </span>
            </button>
          </div>
        </>
      </Modal>
      <section className="container">
        <h1 className="mb-3 content-header">
          <Trans i18nKey={"REGISTRATION_FORM"} />
        </h1>

        <p>
          <Trans i18nKey={"JOINING_OUR_TEAM"} />
        </p>

        <RegisterForm />
      </section>
    </Layout>
  );
};

export default Register;
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
